import { storefront, cart, home, newspaper, personCircle, } from 'ionicons/icons';
import { cardOutline, logoPaypal, logoAlipay, logoWechat, businessOutline, } from 'ionicons/icons';

export default {
  versionCode: '3.0.0',
  websiteUrl: 'https://www.fairbuy.hk/',
  
  primaryColor: '#64bbb4',
  logo: require('@/assets/logo.png'),
  logoWithText: require('@/assets/logo.png'),

  Firebase: {
    apiKey: "AIzaSyAr_3fj9Qd1-RVWxcaWlJLoiciFb6FDEQM",
    authDomain: "fairbuyhk.firebaseapp.com",
    projectId: "fairbuyhk",
    storageBucket: "fairbuyhk.appspot.com",
    messagingSenderId: "849446322165",
    appId: "1:849446322165:web:335205fd74d1ae0674eabc",
    measurementId: "G-HPGFQYJL3F"
  },

  Stripe: {
    publishableKey: 'pk_test_51HVue8F5eqkgmghRoS3cNn8SMdn9ou9PIffiirbX1a4BvdgPSgCGHWvX6EO4XQF6ppPZdkFxyJHE8MmwGs2aby8P00g5qBbCG0', // test
  },

  OneSignal: {
    appId: 'a1bb338b-8156-426d-bd3b-ff5ccb46e9e3',
  },

  authPages: ["/forgot-password", "/login", "/register"],
  memberPages: ["/checkout", "/profile", "/bookings", "/services", '/notifications', "/posts"],

  HomePage: {
    banner: {
      slidingSpeed: 1000, // Duration of transition between slides (in ms)
      autoPlayDelay: 3000, // Delay between transitions (in ms)
      desktopSlidesPerView: 2,
    }
  },

  tabs: [
    { icon: home, slug: 'home', href: 'home' },
    { icon: storefront, slug: 'products', label: 'shop', href: 'products?category=' },
    { icon: cart, slug: 'cart', label: 'cart', href: 'cart' },
    { icon: newspaper, slug: 'posts', label: 'forum', href: 'posts' },
    { icon: personCircle, slug: 'profile', label: 'profile', href: 'profile' }, 
  ],

  paymentMethods: [
    { key: "Credit Card", value: "card", icon: cardOutline },
    { key: "PayPal", value: "paypal", icon: logoPaypal },
    { key: "AlipayHK", value: "alipayhk", icon: logoAlipay },
    { key: "WeChat Pay HK", value: "wechatpayhk", icon: logoWechat },
    { key: "Bank Transfer", value: "bank", icon: businessOutline },
  ],
}