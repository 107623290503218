import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  // HOME page
  async getPublicData() {
    // TODO: pagination for products
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getPublicData')({})).data;
  },

  async createNewFeedback(name: any, email: any, feedback: any, product: any = null) {
    return await firebase.app().functions('asia-east2').httpsCallable('common-createNewFeedback')({ name, email, feedback, product });
  },

  async getUserNotifications() {
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getUserNotifications')()).data;
  }
}