export default {
  receiveAppPublicData(state, data) {
    const { coaches, productCategories, products, companyInfo, posts, pickupPoints, bookingServices, banners, } = data;
    const userId = state.user.id;

    state.allProducts = products.map(p => {
      p.purchasedByUser = userId ? p.relatedUserPurchasedItems.includes(userId) : false;
      p.likedByUser = userId ? p.relatedUserLikedItems.includes(userId) : false;
      p.photoLinks = p.photoLinks ? p.photoLinks.split(" , ") : [];
      return p;
    });
    state.allPosts = posts.map(p => {
      p.photoLinks = p.photoLinks ? p.photoLinks.split(" , ") : [];
      return p;
    });
    state.allProductCategories = productCategories;

    if (coaches) state.coaches = coaches;
    if (bookingServices) {
      state.bookingServices = bookingServices;
      state.fetchedBookingServices = true;
    }
    if (pickupPoints) state.pickupPoints = pickupPoints;
    if (companyInfo) state.companyInfo = companyInfo;
    if (banners) state.homeBanners = banners.filter(b => b.image && b.page == 'home').sort((a, b) => a.order - b.order);

    state.loadingAppPublicData = false;
  },

  receivePickupPoints(state, data) {
    state.pickupPoints = data;
  },
  receiveNotifications(state, data) {
    state.allNotifications = data;
    state.loadingNotifications = false;
  },

  // USER
  loadingUser(state) {
    state.loadingUser = true;
  },
  receiveUser(state, user) {
    const userId = user.id;
    state.allProducts.forEach(product => {
      // check if the product is liked by the logged in user
      product.purchasedByUser = userId ? product.relatedUserPurchasedItems.includes(userId) : false;
      product.likedByUser = userId ? product.relatedUserLikedItems.includes(userId) : false;
    });
    state.user = user;
    state.loadingUser = false;
    state.loggedIn = (userId ? true : false);
  },
  addBlockedUser(state, userId) {
    state.user.relatedBannedUsers += ` , ${userId}`;
  },
  removeBlockedUser(state, userId) {
    state.user.relatedBannedUsers = state.user.relatedBannedUsers.replace(new RegExp(userId, 'g'), "");
  },

  // CART ITEMS
  loadingCartItems(state) {
    state.loadingCartItems = true; 
  },
  receiveCartItems(state, cartItems) {
    state.cartItems = cartItems;
    state.loadingCartItems = false;
  },
  upsertCartItem(state, updatedCartItem) {
    const idx = state.cartItems.findIndex(item => item.productId == updatedCartItem.productId);
    if (idx !== -1) {
      state.cartItems.splice(idx, 1, updatedCartItem);
    } else {
      state.cartItems.push(updatedCartItem);
    }
  },

  // POSTS
  receiveAllPosts(state, allPosts) {
    allPosts.forEach(p => {
      p.photoLinks = p.photoLinks ? p.photoLinks.split(" , ") : [];
    });
    state.allPosts = allPosts;
    state.loadingPosts = false;
  },
  updatePost(state, updatedPost) {
    state.allPosts = [
      ...state.allPosts.map(post => post.id !== updatedPost.id ? post : {...post, ...updatedPost})
    ];
  },
  deletePost(state, targetPostId) {
    const idx = state.allPosts.findIndex(post => post.id == targetPostId);
    state.allPosts.splice(idx, 1);
  },

  // BOOKINGS
  receiveBookingServices(state, data) {
    state.bookingServices = data;
    state.fetchedBookingServices = true;
  },
  receivePurchasedServices(state, purchasedServices) {
    state.purchasedServices = purchasedServices;
    state.loadingPurchasedServices = false;
  },
  receiveBookings(state, bookings) {
    state.bookings = bookings;
    state.loadingBookings = false;
  },
  updateBooking(state, updatedBooking) {
    state.bookings = [
      ...state.bookings.map(booking => booking.id !== updatedBooking.id ? booking : {...booking, ...updatedBooking})
    ]; 
  }
}