import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/storage';
import Compressor from 'compressorjs'; // for compressing image size before upload

export default {
  async createNewUser(newUser: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-createNewUser')(newUser);
    return res.data[0]; // return the new user
  },

  async getLoggedInUser() {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-getLoggedInUser')({});
    return res.data[0];
  },
  
  async updateLoggedInUser(firstName: any, lastName: any, phone: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-updateLoggedInUser')({ firstName, lastName, phone });
    return res.data[0]; // return the updated user
  },
  
  async deleteLoggedInUser() {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-deleteLoggedInUser')();
    return res.data; // return the new user
  },

  async updateLoggedInUserProfilePic(dataUrl: any, fileName: string, originalImageLink: any = null) {
    const response = await fetch(dataUrl);
    const blob = await response.blob();
    const imageFile: any = await new Promise((resolve, reject) => {
      new Compressor(blob, {
        maxWidth: 1000,
        quality: 0.6,
        success: resolve,
        error: reject,
      });
    });
    const snapshot = await firebase.storage().ref(`users-${fileName}`).put(imageFile);
    const imageLink = await snapshot.ref.getDownloadURL();
    if (originalImageLink) {
      try {
        // prevent problem of invalid image URL
        firebase.storage().refFromURL(originalImageLink).delete(); // delete the old image from Firebase storage
      } catch (e) {
        console.log(e);
      }
    }
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-updateLoggedInUserProfilePic')({ imageLink });
    return res.data[0]; // return the updated user
  },

  async updateUserDarkThemeSetting(enabledDarkTheme: boolean) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-updateUserDarkThemeSetting')({ enabledDarkTheme })).data[0];
  },

  async updateUserAppLanguage(locale: string) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-updateUserAppLanguage')({ locale })).data[0];
  },

  async updateLastCheckNotificationTime() {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-updateUserLastCheckNotificationTime')()).data[0];
  }
}