import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  // READ
  async getUserPurchasedServices() {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-getUserPurchasedServices')({ })).data;
  },
  async getUserBookings() {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-getUserBookings')({ })).data;
  },
  async getUpcomingOccupiedTimeSlots() {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-getUpcomingOccupiedTimeSlots')({ })).data;
  },
  async getBookingServices() {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-getBookingServices')({ })).data;
  },

  // ADD
  async createNewBooking(booking: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('booking-createNewBooking')({ booking });
  },
  async batchCreateBookings() {
    // according to user's preferences (Mon - Sun, Morning / Afternoon / Night)
    return true;
  },

  // UPDATE
  async cancelBooking(bookingId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-cancelBooking')({ bookingId })).data;
  },
  async updateBooking() {
    return true;
  }
}