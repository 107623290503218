import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _withDirectives(_createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (tab) => {
                return (_openBlock(), _createBlock(_component_ion_tab_button, {
                  key: tab.slug,
                  tab: tab.slug,
                  href: `/${tab.href}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: tab.icon
                    }, null, 8, ["icon"]),
                    (tab.slug == 'cart' && $setup.numOfCartItems > 0)
                      ? (_openBlock(), _createBlock(_component_ion_badge, {
                          key: 0,
                          id: "notifications-badge",
                          color: "danger"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.numOfCartItems), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (tab.label)
                      ? (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t(tab.label)), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["tab", "href"]))
              }), 128))
            ]),
            _: 1
          }, 512), [
            [_vShow, !$setup.hideTabs]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}