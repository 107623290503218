import parentConfig from '@/app-template/lib/config';
import { storefront, cart, home, newspaper, personCircle, calendar, notifications,
        cardOutline, logoPaypal, logoAlipay, logoWechat, businessOutline, } from 'ionicons/icons';

export default {
  ...parentConfig,
  
  versionCode: '1.0.0',
  websiteUrl: 'https://www.a-gallery.com.hk',
  
  primaryColor: '#c32334',
  logo: require('@/assets/logo.png'),
  logoWithText: require('@/assets/logo.png'),

  Firebase: {
    apiKey: "AIzaSyD38nrxY3TmaHMA7YTRTXw8mU6LV2Z4T_A",
    authDomain: "a-gallery-tvp.firebaseapp.com",
    projectId: "a-gallery-tvp",
    storageBucket: "a-gallery-tvp.appspot.com",
    messagingSenderId: "886688376202",
    appId: "1:886688376202:web:093acb9946afed419a9473"
  },

  Stripe: {
    //publishableKey: 'pk_test_51HVue8F5eqkgmghRoS3cNn8SMdn9ou9PIffiirbX1a4BvdgPSgCGHWvX6EO4XQF6ppPZdkFxyJHE8MmwGs2aby8P00g5qBbCG0', // test
    publishableKey: 'pk_live_51HVue8F5eqkgmghRn1B9EOEvN9FLUN1kshEHg7xRYqmP8OT2YaBraba2PVCqLqH6GY2EuaQH3CpcuKFM1uS8QdFu00W7JYQlu1', // live
  },

  OneSignal: {
    appId: '7cc6be77-cad5-45f4-b502-a4b02cd1b126',
  },

  HomePage: {
    banner: {
      slidingSpeed: 1000, // Duration of transition between slides (in ms)
      autoPlayDelay: 3000, // Delay between transitions (in ms)
      desktopSlidesPerView: 1,
    }
  },

  authPages: [...parentConfig.authPages],
  memberPages: [...parentConfig.memberPages],
  extraRootRoutes: [], // example: { path: '/login', component: () => import('@/pages/LoginPage.vue') }
  extraTabRoutes: [
    {
      path: 'services',
      component: () => import('@/pages/ServiceListPage.vue')
    },
    {
      path: 'bookings',
      component: () => import('@/pages/BookingPage.vue'),
    },
    {
      path: "bookings/:id",
      name: "BookingDetailPage",
      component: () => import("@/pages/BookingDetailPage.vue"),
      props: true,
    },
    {
      path: 'notifications',
      name: 'NotificationListPage',
      component: () => import('@/pages/NotificationListPage.vue'),
    },
    {
      path: 'notifications/:id',
      name: 'NotificationDetailPage',
      component: () => import("@/pages/NotificationDetailPage.vue"),
      props: true,
    },
  ], // example: { path: 'home', component: () => import('@/pages/HomePage.vue') }

  paymentMethods: [
    { key: "Credit Card", value: "card", icon: cardOutline },
    { key: "PayPal", value: "paypal", icon: logoPaypal },
    { key: "AlipayHK", value: "Alipay", icon: logoAlipay },
    //{ key: "WeChat Pay HK", value: "wechatpayhk", icon: logoWechat },
    { key: "Bank Transfer", value: "bank", icon: businessOutline },
  ],
  
  tabs: [
    { icon: home, slug: 'home', href: 'home' },
    { icon: storefront, slug: 'products', label: 'shop', href: 'products?category=' },
    //{ icon: notifications, slug: 'notifications', label: 'notifications'},
    { icon: cart, slug: 'cart', label: 'cart', href: 'cart' },
    { icon: newspaper, slug: 'posts', label: 'forum', href: 'posts' },
    { icon: calendar, slug: 'bookings', label: 'bookings', href: 'bookings' },
    { icon: personCircle, slug: 'profile', label: 'profile', href: 'profile' }, 
  ],
}