import parentTranslationMessages from '@/app-template/lib/i18n';

export default {
  zh: {
    ...parentTranslationMessages.zh,
    coachIntro: "醫生介紹",
    // add extra zh translations below
  },
  en: {
    ...parentTranslationMessages.en,
    coachIntro: "Doctors",
    // add extra en translations below
  }
}