export default {
  zh: {
    shop: '商城',
    cart: '購物車',
    checkout: '結帳',
    forum: '討論區',
    profile: '帳戶',
    services: '服務',
    bookings: '預約',
    notifications: '通知',
    settings: '設定',
    inquiry: '查詢',

    deleteAccount: '刪除帳戶',
    confirmDeleteAccountMsg: '注意：確定刪除此帳戶及所有相關資料?',
    accountDeleted: '帳戶已刪除',

    sale: '特價',
    soldOut: '售罊',

    yes: '是',
    no: '否',
    back: '返回',
    confirm: '確定',
    cancel: '取消',
    all: '全部',

    remark: '備註',
    submit: '提交',

    buyNow: '立即購買',
    addToCart: '加入購物車',
    addToLikedItems: '收藏',
    removeFromLikedItems: '從我的清單移除',
    enquiry: '聯繫客服',
    product: '產品',
    
    successUpdateLikedItems: '成功更新喜愛清單',
    successAddedToCart: '成功加到購物車',

    errorHeader: '錯誤！',

    // Booking
    pending: '待確認',
    confirmed: '已確認',
    upcoming: '即將到來',
    ongoing: '進行中',
    processing: '處理中',
    completed: '已完成',
    cancelled: '已取消',
    minutes: '分鐘',
    bookingDetails: '預約詳情',
    bookedServices: '預約服務：',
    bookingQRCode: '預約 QR Code',
    changeBookingTime: '更改預約時間',
    cancelBooking: '取消預約',
    confirmCancel: '確認取消',
    confirmCancelBooking: '確定取消此預約？',
    bookingCancelled: '已取消預約。',
    newBooking: '新預約',
    timesPurchased: '購買總次數：',
    timesUsed: '已使用：',
    timesRemaining: '尚餘：',
    bookingDate: '預約日期',
    selectBookingDate: '請選擇預約日期',
    bookingTime: '預約時間',
    selectBookingTime: '請選擇預約時間',
    updateBooking: '更改預約',
    createBooking: '預約',
    successCreateBooking: '預約成功！',
    successUpdateBooking: '己成功更改預約。',
    beforeTreatment: '療程前',
    afterTreatment: '療程後',

    deliveryMethod: '送貨方式：',
    deliveryDistrict: '送貨地區：',
    deliveryAddress: '送貨地址：',
    customerName: '客戶姓名：',
    contactPhone: '聯絡電話：',
    customerEmail: '電郵地址：',

    CheckoutPage: {
      yourOrder: '您的訂單',
      orderRemark: '訂單備註',
      specialIntructions: '給賣家的特別指示',
      deliveryInfo: '送貨資料',
      selectDistrict: '請選擇送貨地區',
      enterDeliveryAddress: '請輸入送貨地址',
      enterCustomerName: '請輸入您的名字',
      enterContactPhone: '請輸入聯絡電話',
      enterCustomerEmail: '請輸入電郵地址',
      contactInfo: '聯絡資料',
      paymentInfo: '付款資料',
      confirmPayment: '確認付款',
      paymentProof: '付款證明',
      pickup: '自取',
      delivery: '送貨上門',
      pickupPoint: '自取點',
      selectPickupPoint: '選擇自取點',
    },
    CartPage: {
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      checkout: '前往付款',
      shopNow: '即去購物',
      emptyCart: '購物車內沒有任何商品',
      removeCartItem: '刪除購物車商品',
      confirmRemoveCartItem: '確定移除此購物車商品？',
    },

    HomePage: {
      seeMore: '查看更多',
      featuredProducts: '熱門推介',
      recommendedForYou: '推䔍給您',
      latestProducts: '最新上架',
      aboutUs: '關於我們',
      contactUs: '聯絡我們',
      companyAddress: '地址：',
      companyEmail: '電郵：',
      companyPhone: '公司電話：'
    },

    OrderPage: {
      order: '訂單',
      orderStatus: '訂單狀態',
      orderItems: '訂單產品',
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      deliveryInfo: '送貨資料',
      orderNumber: '訂單號碼：',
      total: '合共：',
    },

    PostPage: {
      newPost: '新帖子',
      lastUpdated: '最後更新：',
      numOfComments: '則留言',
      newComment: '新增留言',
      commentBtnText: '留言',
      editPost: '編輯帖子',
      postTitle: '帖子主題',
      postContent: '帖子內容',
      postImage: '圖片',
      updatePost: '更新帖子',
      publishPost: '發佈帖子',
      successPublishPost: '已成功發佈帖子。',
      successUpdatePost: '已成功更新帖子。',
      confirmDelete: '確認刪除',
      confirmDeletePost: '確認刪除此帖子？',
      deletePost: '刪除帖子',
      postDeleted: '已刪除帖子。',
      report: '舉報帖子',
      block: '封鎖用戶',
      unblock: '解除封鎖用戶',
      blockedContent: '已封鎖內容',
      confirmUnbanUser: '確認解除封鎖 user ？',
      unblockedUser: '已解除封鎖所有來自 user 的帖子',
      confirmBanUser: '確認封鎖所有來自 user 的帖子？',
      blockedUser: '已封鎖所有來自 user 的帖子',
      confirmReport: '我們會於閣下提交舉報後24小時內審閱此帖子，一經確實帖子含有不當或令人反感之內容，我們會立刻移除帖子並封鎖發表帖子的用戶。',
      reportDescription: '描述 (選填)',
      reportedPost: '已舉報帖子',
    },

    ProductPage: {
      numOfCustomerReviews: '買家評論',
      newReview: '寫評論',
      successPublishProductReview: '已成功發佈商品評論。',
      reviewRating: '評分',
      reviewContent: '評論',
      publishReview: '發表評論',
    },

    LoginPage: {
      login: '登入',
      password: '密碼',
      loginEmail: '登入電郵',
    },

    ForgotPasswordPage: {
      loginEmail: '登入電郵',
      forgotPassword: '忘記密碼',
      sendResetPasswordEmail: '發送重設密碼電郵',
      resetPswEmailSentTo: '重設密碼電郵已發送至',
      checkEmailReset: '請檢查您的郵箱並透過電郵內的連結重設登入密碼。',
      backToLogin: '返回登入頁面',
      enterLoginEmail: '請輸入登入電郵。',
    },

    RegisterPage: {
      registerAccount: '註冊帳號',
      loginEmail: '登入電郵*',
      password: '密碼*',
      confirmPassword: '確認密碼*',
      register: '註冊',
      inconsistentPasswordMsg: '兩次輸入的密碼不一致，請重新輸入。',
      enterEmailAndPassword: '請輸入電郵及密碼。',
    },

    ThankYouPage: {
      thankYouForYourOrder: '感謝您的訂單！',
      description: '稍候您將會收到訂單確認通知。',
      backToHomePage: '返回主頁',
    },

    UserProfilePage: {
      changeProfilePhoto: '更換頭像',
      successChangeProfilePhoto: '個人頭像更換成功！',
      editPersonalInfo: '修改個人資料',
      successUpdatePersonalInfo: '已成功更新個人資料。',
      changePassword: '更改密碼',
      oldPassword: '現有密碼',
      newPassword: '新密碼',
      newPasswordConfirm: '確認新密碼',
      inconsistentNewPasswordMsg: '兩次輸入的新密碼不一致，請重新輸入。',
      wrongOldPassword: '現有密碼輸入錯誤，請重新輸入。',
      successUpdatePassword: '成功更改密碼！',
      enterAllFields: '請輸入所有欄位。',
      myOrders: '訂單紀錄',
      myPosts: '發帖紀錄',
      likedItems: '喜愛產品',
      language: '語言',
      darkTheme: '深色主題',
      termsAndConditions: '責任及條款',
      feedback: '意見專區',
      logout: '登出',
      confirmLogout: '確定登出？',
      lastName: '姓氏',
      firstName: '名字',
      emailAddress: '電郵地址',
      phone: '手機號碼',
      save: '儲存',
    },
    FeedbackPage: {
      contactInfo: '聯絡資料',
      nickname: '您的名字',
      enterNickname: '請輸入您的名字',
      email: '您的電郵',
      enterEmail: '請輸入您的電郵',
      feedback: '意見反饋',
      enterFeedback: '在此輸入您的意見',
      feedbackSubmitted: '意見提交成功！',
    },
  },
  en: {
    shop: 'Shop',
    cart: 'Cart',
    checkout: 'Checkout',
    forum: 'Forum',
    profile: 'Profile',
    services: 'Services',
    bookings: 'Bookings',
    notifications: 'Notifications',
    settings:'Settings', 
    inquiry: 'Inquiry',
    
    deleteAccount: 'Delete Account',
    confirmDeleteAccountMsg: 'Confirm delete this account and all related personal information?',
    accountDeleted: 'Account deleted.',

    sale: 'SALE',
    soldOut: 'SOLD OUT',

    yes: 'Yes',
    no: 'No',
    back: 'Back',
    confirm: 'Confirm',
    cancel: 'Cancel',
    all: 'All',

    remark: 'Remark',
    submit: 'Submit',
    
    buyNow: 'Buy Now',
    addToCart: 'Add to Cart',
    addToLikedItems: 'Add to My List',
    removeFromLikedItems: 'Remove from My List',
    enquiry: 'Enquiry',
    product: 'Product',

    successUpdateLikedItems: 'List of liked items updated successfully!',
    successAddedToCart: 'Item added to cart successfully!',

    errorHeader: 'ERROR!',

    // bookings
    pending: 'Pending',
    confirmed: 'Confirmed',
    upcoming: 'Upcoming',
    ongoing: 'Ongoing',
    completed: 'Completed',
    cancelled: 'Cancelled',
    minutes: 'minutes',
    bookingDetails: 'Booking Details',
    bookedServices: 'Booked Services: ',
    bookingQRCode: 'Booking QR Code',
    changeBookingTime: 'Change Booking Time',
    cancelBooking: 'Cancel Booking',
    confirmCancel: 'Confirm Cancel',
    confirmCancelBooking: 'Confirm cancel this booking?',
    bookingCancelled: 'Booking cancelled.',
    newBooking: 'New Booking',
    timesPurchased: 'Times Purchased: ',
    timesUsed: 'Used: ',
    timesRemaining: 'Remaining: ',
    bookingDate: 'Booking Date',
    selectBookingDate: 'Please select the booking date',
    bookingTime: 'Booking Time',
    selectBookingTime: 'Please select the booking time',
    updateBooking: 'Edit Booking',
    createBooking: 'Create Booking',
    successCreateBooking: 'Booking created successfully!',
    successUpdateBooking: 'Booking updated successfully!',
    beforeTreatment: 'Before Treatment',
    afterTreatment: 'After Treatment',

    deliveryMethod: 'Delivery Method: ',
    deliveryDistrict: 'Delivery District: ',
    deliveryAddress: 'Delivery Address: ',
    customerName: 'Customer Name: ',
    contactPhone: 'Contact Phone: ',
    customerEmail: 'Email Address: ',

    CheckoutPage: {
      yourOrder: 'Your Order',
      orderRemark: 'Order Remark',
      specialIntructions: 'Special Intructions to the Seller',
      deliveryInfo: 'Delivery Information',
      selectDistrict: 'Select the delivery district',
      enterDeliveryAddress: 'Enter the delivery address',
      enterCustomerName: 'Enter your name',
      enterContactPhone: 'Enter the contact phone',
      enterCustomerEmail: 'Enter the email address',
      contactInfo: 'Contact Information',
      paymentInfo: 'Payment Information',
      confirmPayment: 'Submit',
      paymentProof: 'Payment Proof',
      pickup: 'Pick up',
      delivery: 'Delivery',
      pickupPoint: 'Pick-up Point',
      selectPickupPoint: 'Select pick-up point',
    },
    CartPage: {
      lineTotal: 'Total: ',
      orderTotal: 'Total: ',
      checkout: 'Checkout',
      shopNow: 'Shop Now',
      emptyCart: 'Your shopping cart is empty',
      removeCartItem: 'Remove Cart Item',
      confirmRemoveCartItem: 'Confirm removing this item from cart?',
    },

    HomePage: {
      seeMore: 'See More',
      featuredProducts: 'Featured Products',
      recommendedForYou: 'Recommended For You',
      latestProducts: 'Latest Products',
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      companyAddress: 'Address: ',
      companyEmail: 'Email: ',
      companyPhone: 'Telephone: ',
    },

    OrderPage: {
      order: 'Order',
      orderStatus: 'Order Status',
      orderItems: 'Order Items',
      lineTotal: 'Line Total: ',
      orderTotal: 'Order Total: ',
      deliveryInfo: 'Delivery Information',
      orderNumber: 'Order ID: ',
      total: 'Total: ',
    },

    PostPage: {
      newPost: 'New Post',
      lastUpdated: 'Last Updated: ',
      numOfComments: ' Comments',
      newComment: 'New Comment',
      commentBtnText: 'COMMENT',
      editPost: 'Edit Post',
      postTitle: 'Post Title',
      postContent: 'Post Content',
      postImage: 'Image',
      updatePost: 'Update Post',
      publishPost: 'Publish Post',
      successPublishPost: 'Post published successfully!',
      successUpdatePost: 'Post updated successfully!',
      confirmDelete: 'Confirm Delete',
      confirmDeletePost: 'Confirm delete this post?',
      deletePost: 'Delete Post',
      postDeleted: 'Post deleted.',
      report: 'Report Post',
      block: 'Block User',
      unblock: 'Unblock User',
      blockedContent: 'Blocked Content',
      confirmUnbanUser: 'Confirm to unblock user?',
      unblockedUser: 'Unblocked all posts from user',
      confirmBanUser: 'Confirm to block all posts from user?',
      blockedUser: 'Blocked all posts from user',
      confirmReport: 'We will review this post within 24 hours after you submit the report. \
                      If it is confirmed that this post contains inappropriate or objectionable content, we will immediately remove the post and eject the user who provided the offending content.',
      reportDescription: 'Description (optional)',
      reportedPost: 'Reported Post',
    },

    ProductPage: {
      numOfCustomerReviews: ' Customer Reviews',
      newReview: 'Write a review',
      successPublishProductReview: 'Review published successfully!',
      reviewRating: 'Rating',
      reviewContent: 'Review',
      publishReview: 'Publish Review',
    },

    LoginPage: {
      login: 'Login',
      password: 'Password',
      loginEmail: 'Email',
    },
    ForgotPasswordPage: {
      loginEmail: 'Login Email',
      forgotPassword: 'Forgot Password',
      sendResetPasswordEmail: 'Send Reset Password Email',
      resetPswEmailSentTo: 'Email has been sent to',
      checkEmailReset: 'Please reset the password by clicking the link in the email.',
      backToLogin: 'Back to Login',
      enterLoginEmail: 'Please enter the login email',
    },
    RegisterPage: {
      registerAccount: 'Register',
      loginEmail: 'Login Email*',
      password: 'Password*',
      confirmPassword: 'Confirm Password*',
      register: 'Register',
      enterEmailAndPassword: 'Please enter both the email address and password.',
      inconsistentPasswordMsg: 'Passwords do not match. Please enter again.',
    },

    ThankYouPage: {
      thankYouForYourOrder: 'Thank you for your order!',
      description: "You will soon receive an order confirmation notification",
      backToHomePage: 'Back to Home',
    },

    UserProfilePage: {
      changeProfilePhoto: 'Change Profile Photo',
      successChangeProfilePhoto: 'Profile photo changed successfully!',
      editPersonalInfo: 'Edit Personal Information',
      successUpdatePersonalInfo: 'Personal information updated successfully!',
      changePassword: 'Change Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
      inconsistentNewPasswordMsg: 'New passwords do not match. Please enter again.',
      wrongOldPassword: 'The old password is wrong. Please enter again.',
      successUpdatePassword: 'Password updated successfully!',
      enterAllFields: 'Please enter all fields.',
      myOrders: 'My Orders',
      myPosts: 'My Posts',
      likedItems: 'Liked Items',
      language: 'Language',
      darkTheme: 'Dark Theme',
      termsAndConditions: 'Terms and Conditions',
      feedback: 'Feedback',
      logout: 'Log Out',
      confirmLogout: 'Confirm log out?',
      lastName: 'Last Name',
      firstName: 'First Name',
      emailAddress: 'Email Address',
      phone: 'Phone',
      save: 'Save',
    },
    FeedbackPage: {
      contactInfo: 'Contact Information',
      nickname: 'Your name',
      enterNickname: 'Enter your name',
      email: 'Your email',
      enterEmail: 'Enter your email',
      feedback: 'Feedback',
      enterFeedback: 'Enter your feedback here',
      feedbackSubmitted: 'Feedback submitted successfully!',
    },
  }
}