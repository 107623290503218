import { createApp } from 'vue'
import App from '@/App.vue'
import { routes } from '@/app-template/lib/router';
import { createRouter, createWebHistory } from '@ionic/vue-router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/app-template/lib/theme/variables.css';
import '@/app-template/lib/theme/global.css';
import '@/theme/variables.css';
import '@/theme/global.css';

/* Firebase */
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';

/* vue-i18n */
import { createI18n } from 'vue-i18n';
import translationMessages from '@/i18n';
import config from '@/config';

/* Vuex */
import store from '@/store'

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SplashScreen } from '@capacitor/splash-screen';

import UserService from '@/services/UserService';
import CommonService from '@/services/CommonService';
import CartService from '@/services/CartService';
import BookingService from '@/services/BookingService';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

document.documentElement.style.setProperty("--ion-color-primary", config.primaryColor);

const i18n = createI18n({
  locale: 'zh', // set locale
  messages: translationMessages
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(IonicVue, { mode: 'md' })
  .use(router)

firebase.initializeApp(config.Firebase);

router.isReady().then(() => {
  /* Retrieve data from AppSheet and save it in Vuex store as state variables */
  CommonService.getPublicData().then(res => {
    store.commit('receiveAppPublicData', res);
    SplashScreen.hide();
  });

  firebase.auth().onAuthStateChanged((user: any) => {
    if (user) { // logged in
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId(user.uid); // Map OneSignal player ID to Firebase User ID
      }
      const redirectPath: any = router.currentRoute.value.query.redirectPath;
      if (redirectPath) {
        router.replace(redirectPath); // redirect to specific page after login
      }
      else if (config.authPages.includes(router.currentRoute.value.path)) {
        router.replace('/home'); // go to home page if the user in auth pages
      }
      store.commit('loadingUser');
      UserService.getLoggedInUser().then(res => {
        // update language & theme settings on login APP
        if (res) {
          document.body.classList.toggle('dark', res.darkTheme == "Y");
          i18n.global.locale = res.preferredLang.toLowerCase();
          store.commit('receiveUser', res);
        } else {
          // user record not exist in DB yet right after registration
          store.commit('receiveUser', {
            id: user.uid,
            email: user.email,
            profilePic: "https://ionicframework.com/docs/demos/api/avatar/avatar.svg",
            preferredLang: i18n.global.locale,
            darkTheme: "N",
          });
        }
      });
      store.commit('loadingCartItems');
      CartService.getUserCartItems().then(res => {
        if (store.state.cartItems.length > 0) {
          // sync local cart items to server
          const newItems: any = [], updatedItems: any = [];
          store.state.cartItems.forEach((localItem: any) => {
            const serverItem = res.find((item: any) => item.productId == localItem.productId);
            if (serverItem) {
              serverItem.quantity = (+serverItem.quantity) + (+localItem.quantity);
              updatedItems.push(serverItem);
            } else {
              res.push(localItem);
              newItems.push(localItem);
            }
          });
          CartService.syncCartItems(newItems, updatedItems);
        }
        store.commit('receiveCartItems', res);
      });
      // bookings
      BookingService.getUserPurchasedServices().then(res => {
        store.commit('receivePurchasedServices', res);
      });
      BookingService.getUserBookings().then(res => {
        store.commit('receiveBookings', res);
      });
      // notifications
      CommonService.getUserNotifications().then(res => {
        store.commit('receiveNotifications', res);
      })
    } else { // logged out
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId("");
      }
      store.dispatch('resetUserData');
      i18n.global.locale = navigator.language.split('-')[0].toLowerCase() == "en" ? "en" : "zh";
      const currentPath = router.currentRoute.value.path;
      if (config.memberPages.includes(currentPath)) {
        router.replace({ path: '/login', query: { redirectPath: currentPath } });
      }
    }
  });
  app.mount('#app');
  
  /* OneSiganl */
  window["plugins"].OneSignal.setAppId(config.OneSignal.appId);
  window["plugins"].OneSignal.setNotificationOpenedHandler((jsonData) => {
    console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    const { additionalData } = (jsonData.notification.payload || {});
    if (additionalData) {
      if (additionalData["app_notification_id"]) {
        // tap notification, go to notification detail page
        if (store.state.loggedIn) {
          CommonService.getUserNotifications().then(res => {
            store.commit('receiveNotifications', res); // refresh list of notifications
          })
          router.replace(`/notifications/${additionalData["app_notification_id"]}`);
        }
      }
    }
  });

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
    console.log("User accepted notifications: " + accepted);
  });
});

router.beforeEach((to, from) => {
  if (firebase.auth().currentUser == null) { // guests
    if (config.memberPages.includes(to.path)) {
      const redirectPath = to.path == '/checkout' ? '/cart' : to.path;
      return { path: '/login', query: { redirectPath } };
    }
  } else { // logged in users
    if (to.path == '/notifications') {
      // update user last click notification tab time
      store.dispatch('updateUserLastCheckNotificationTime');
    }
  }
})