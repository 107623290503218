export default {
  loadingAppPublicData: true,

  pickupPoints: [],
  companyInfo: {},
  allProducts: [],
  allProductCategories: [],
  allPosts: [],
  homeBanners: [],

  allNotifications: [],
  loadingNotifications: true,

  user: {},
  loggedIn: false,
  loadingUser: false,

  cartItems: [],
  loadingCartItems: true,

  purchasedServices: [],
  loadingPurchasedServices: true,

  bookings: [],
  loadingBookings: true,
}